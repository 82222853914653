<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="query.stationId"
          placeholder="站点"
          filterable
          clearable
        >
         
          <template v-for="(item, index) in stationList.list">
            <el-option
              v-if="item.stationMacId != ''"
              :label="item.stationName"
              :value="item.id"
            ></el-option>
          </template>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="doExportAmmeters"
          >导出电量数据</el-button
        >
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="站点名称" prop="stationName"></el-table-column>
      <el-table-column label="站点编号" prop="stationMacid"></el-table-column>
      <el-table-column label="借出电池数" prop="sendTotal"></el-table-column>
      <el-table-column label="归还电池数" prop="backTotal"></el-table-column>
      <el-table-column label="电表读数" prop="usepower" align="right">
        <template #default="scope">
          {{ parseFloat(scope.row.usepower).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column label="耗电量" prop="daypower" align="right">
        <template #default="scope">
          {{ parseFloat(scope.row.daypower).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column label="日期" prop="sumday">
        <template #default="scope">
          {{ moment(scope.row.sumday).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
 
<script>
import { fetchAmmeters, exportAmmeters } from "@/api/station";
import { mapState } from "vuex";
import moment from "moment";
export default {
  computed: mapState({
    stationList: (state) => {
      return state.station.stationList || { list: [], map: {} };
    },
  }),

  data() {
    let startTime = moment()
      .subtract(1, "month")
      .startOf("day")
      .subtract(1, "s")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

    return {
      moment: moment,
      dateRange: [startTime, endTime],
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },

      query: {
        stationMacId: "",
        stationId:""
      },
      total: 0,
    };
  },
  methods: {
    doExportAmmeters() {
      exportAmmeters({
        startTime: this.query.startTime,
        endTime: this.query.endTime,
      }).then((res) => {
         this.util.apiDownLoadFile(res.data,"application/vnd.ms-excel",`${this.query.startTime}-${this.query.endTime}站点电量.xlsx`)
      });
    },
    changeDate(e) {
      if (!this.validatenull(e)) {
        //this.query.sumday = moment(e).format("YYYY-MM-DD HH:mm:ss");

        this.query.startTime = moment(e[0])
          .startOf("day")
          .subtract(1, "s")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        this.dateRange = [this.query.startTime, this.query.endTime];
      }
    },
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchAmmeters({...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records || [];
          this.total = res.data.data.total;
          this.dataSource.map((item) => {
            item.operationName = this.agentMap[item.operationId];
          });
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch("fetchStationAll");
    this.getList();
  },
};
</script>
